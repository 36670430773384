.dash > .adm-grid {
  margin-bottom: 15px;;
}

.dash .adm-card-body .spinner-container {
  height: 200px;
}

.dash-card-title {
  margin-bottom: 5px;
  font-weight: 500;
}

.dash-card-filter {
  font-weight: normal;
}

.dash-params-wrapper {
  margin-bottom: 20px;
}

.dash-params {
  margin-right: 30px;
  font-size: 18px;
  font-weight: 500;
}

.dash-dyn-pers {
  margin-right: 15px;
  font-weight: 500;
}

.dash-dyn-comp {
  font-size: 12px;
}

.dash .adm-card {
  height: 325px;
  margin-bottom: 15px;
}
