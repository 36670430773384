.login {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 0;
  background-color: #fff;
}

.login > .adm-grid {
  width: 100%;
  padding: 0 15px;
}

.login-logo {
  width: 15%;
  margin: auto auto 40px;
}

.login .adm-list-item {
  padding-left: 0;
  border: none;
  border-bottom: 2px solid #808080!important;
}

.login .adm-form {
  --border-top: none;
}

.login-pass,
.login-button {
  margin-bottom: 20px;
}

.login-forgot,
.login-lang-wrapper,
.login-reg {
  margin-bottom: 40px;
}

.login-reg {
  text-align: center;
}

.login-forgot-link,
.login-reg-link {
  font-weight: 700;
  color: #18a7bc;
}

.login-lang-button.adm-button.active,
.login-lang-button.adm-button-active {
  background-color: #fff!important;
  font-weight: 600;
  color: #5d5ca7;
}

.login-info-link {
  color: #202020;
}

.login-info-link:hover {
  border-bottom: 1px solid #202020;
}

@media (min-width: 768px) {
  .login > .adm-grid {
    width: 500px;
  }
}