.dp-title {
  width: 100%;
  margin-bottom: 25px;
}

.dp-custom-prev,
.dp-custom-next {
  width: 40px;
  height: 40px;
}

.dp-custom-next {
  transform: rotate(180deg);
}

.dp-custom-prev:hover::after,
.dp-custom-prev:focus::after,
.dp-custom-next:hover::after,
.dp-custom-next:focus::after {
  border-color: #bec3cd;
}

.dp-custom-prev:hover::before,
.dp-custom-prev:focus::before,
.dp-custom-next:hover::before,
.dp-custom-next:focus::before {
  background-color: #bec3cd;
}

.dp-custom-prev:active,
.dp-custom-next:active,
.dp-custom-prev:focus,
.dp-custom-next:focus {
  outline: none;
}

.dp-custom-prev::before,
.dp-custom-next::before,
.dp-custom-prev::after,
.dp-custom-next::after {
  content: "";
  position: absolute;
  display: block;
}

.dp-custom-prev::before,
.dp-custom-next::before {
  top: 21px;
  left: 13px;

  width: 12px;
  height: 3px;

  background-color: #70778b;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dp-custom-prev::after,
.dp-custom-next::after {
  top: 16px;
  left: 13px;

  transform: rotate(45deg);
  width: 13px;
  height: 13px;

  border: 3px solid #70778b;
  border-top: none;
  border-right: none;
}

.dp-title-month {
  color: #000;
  font-size: 17px;
  font-weight: 500;
}

.dp-date {
  width: 30px;
  height: 30px;
  margin: auto;
  padding: 5px;
}

@media (min-width: 380px) {
  .dp-date {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
}

.dp-date-current {
  color: #fff;
  background-color: #5d5ca7;
  border-radius: 50%;
}

.dp-table {
  width: 100%;
}

.dp-th {
  padding-bottom: 20px;
  text-align: center;
  color: #808080;
  font-weight: 500;
}

.dp-td {
  text-align: center;
  font-weight: 500;
}

.dp-another-month {
  color: #c8c8c8;
}
