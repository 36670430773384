/* Ant Design */

.calendar .adm-collapse-panel-content {
  color: #0d152e;
}

.calendar .adm-notice-bar {
  margin: 10px 0;
}

.calendar .adm-collapse .adm-list {
  --border-top: none;
  --border-bottom: none;
  --border-inner: none;
}

/*-----------------------------------------------*/

.calendar .date-title {
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.calendar .picker {
  margin: 15px 0;
}

.calendar .color-ind {
  margin-bottom: 15px;
}

.calendat .total {
  height: 150px;
  margin: 15px 0;
}

.calendar .category-item {
  margin-bottom: 15px;
  font-weight: 500;
}

.calendar .rec-price {
  font-size: 32px;
}

.calendar .hint-price {
  margin-right: 10px;
}

.calendar .self-price {
  color: #5d5ca7;
}

.calendar .cur-price-wrapper span:first-child {
  margin-right: 20px;
}

.calendar .cur-price-wrapper {
  padding: 10px 0;
}

.calendar .data-table {
  width: 100%;
}

.calendar .data-table td:first-child {
  font-size: 15px;
  font-weight: 400;
}

.calendar .data-table td:nth-child(2) {
  padding: 10px 20px;
}

.calendar .visual-block {
  min-width: 60px;
  width: 100%;
}
