.spinner-container {
  display: flex;
  height: calc(100% - 50px);
  min-height: 50px;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.spinner-container-full {
	display: flex;
  flex-direction: column;
  justify-content: center;
}

.spinner-container-fuull .spinner {
  margin: 20px 0;
}

.spinner-letter-whitespace {
  margin-right: 10px;
}

.spinner-letter-container {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.spinner-letter {
	color: #0D152E;
	font-size: 24px;
	font-weight: 500;
	animation-name:bounce_loadingText;
	animation-duration:2.09s;
	animation-iteration-count:infinite;
	animation-direction:normal;
	transform:scale(.5);
}

.spinner-letter_1 {
	animation-delay:0.10s;
}
.spinner-letter_2 {
	animation-delay:0.25s;
}
.spinner-letter_3 {
	animation-delay:0.40s;
}
.spinner-letter_4 {
  animation-delay:0.55s;
}
.spinner-letter_5 {
  animation-delay:0.70s;
}
.spinner-letter_6 {
  animation-delay:0.85s;
}
.spinner-letter_7{
  animation-delay:1.00s;
}
.spinner-letter_8{
  animation-delay:1.15s;
}
.spinner-letter_9{
  animation-delay:1.30s;
}
.spinner-letter_10 {
  animation-delay:1.45s;
}
.spinner-letter_11 {
  animation-delay:1.60s;
}
.spinner-letter_12 {
  animation-delay:1.75s;
}
.spinner-letter_13 {
  animation-delay:1.90s;
}
.spinner-letter_14 {
  animation-delay:2.05s;
}
.spinner-letter_15 {
  animation-delay:2.20s;
}
.spinner-letter_16 {
  animation-delay:2.35s;
}
.spinner-letter_17 {
  animation-delay:2.50s;
}

@-webkit-keyframes bounce_loadingText{
	0%{
		-webkit-transform:scale(1);
		        transform:scale(1);
		color:#0D152E;
	}

	100%{
		-webkit-transform:scale(.5);
		        transform:scale(.5);
		color:#ECF0F4;
	}
}

@keyframes bounce_loadingText{
	0%{
		-webkit-transform:scale(1);
		        transform:scale(1);
		color:#0D152E;
	}

	100%{
		-webkit-transform:scale(.5);
		        transform:scale(.5);
		color:#ECF0F4;
	}
}
