.empty-block {
  display: flex;
  height: calc(100% - 50px);
  min-height: 50px;
  color: #5553CE;
  font-weight: 600;
  font-size: 18px;
}

.empty-block > div {
  margin: auto;
}
