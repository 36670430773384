.menu .adm-nav-bar {
  position: relative;  
  color: #5d5ca7!important;
  font-weight: 500;
  background-color: #fff;
  z-index: 3;
}

.menu .adm-nav-bar-back {
  display: none;  
}

.menu .adm-nav-bar-left {
  font-size: 16px;
}

.menu .adm-nav-bar-right {
  display: flex;
  justify-content: flex-end;
}

.menu-icon {
  width: 25px;
}

.menu-filter {
  width: 23px;
  margin-right: 30%;
}

.menu-lang {
  width: 15px;
  margin-right: 15px;
}

.main-popup .adm-list {
  --border-top: none;
  --border-bottom: none;
  --border-inner: none;
}

.main-popup .adm-popup-body {
  height: auto;
}

.menu-user-wrapper {
  margin-bottom: 10px;
}

.menu-user-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5d5ca7;
}

.menu-dot-wrapper {
  display: flex;
  align-items: center;
}

.menu-dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 30px;
  background-color: #c8c8c8;
  border-radius: 50%;
}

.nav-link:not(.active) .adm-list-item {
  color: #202020;
}

.nav-link .svg path {
  fill: #202020;
}

.nav-link.active .adm-list-item .adm-list-item-content,
.nav-link:hover .adm-list-item .adm-list-item-content,
.nav-link:focus .adm-list-item .adm-list-item-content {
  color: #5d5ca7;
}

.nav-link.active svg path:not(.init),
.nav-link.active svg rect:not(.init),
.nav-link:hover svg path:not(.init),
.nav-link:hover svg rect:not(.init),
.nav-link:focus svg path:not(.init),
.nav-link:focus svg rect:not(.init) {
  fill: #5d5ca7;
}

.menu-currency-wrapper {
  display: flex;
  justify-content: space-between;
}

.menu-currency {
  color: #777;
}
