@font-face {
  font-family: "GraphikLCG";
  src: url("./fonts/GraphikLCG-Regular.woff2") format("woff2"), url("./fonts/GraphikLCG-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikLCG";
  src: url("./fonts/GraphikLCG-Medium.woff2") format("woff2"), url("./fonts/GraphikLCG-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikLCG";
  src: url("./fonts/GraphikLCG-Semibold.woff2") format("woff2"), url("./fonts/GraphikLCG-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root:root {
  --adm-color-primary: #5553ce;
}

.adm-button-default.adm-button-fill-outline {
  --text-color: #5553ce;
  --border-color: #5553ce;
  background-color: #fff;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 14:39:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "GraphikLCG", sans-serif;

  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: #0D152E;
  background-color: #ecf0f4;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

a {
	background: transparent;
	text-decoration: none;
}

p {
  margin: 0;
}

.main-spinner {
  height: calc(100vh - 50px);
}

.invisible-button {
  position: relative;
  padding: 0;
  display: block;
  color: transparent;
  background-color: initial;
  border: none;
}

.invisible-button-mr {
  margin-right: 15px;
}

.dyn-positive {
  color: #18a7bc;
}

.nb-positive {
  color: #138808;
}

.dyn-negative,
.nb--negative {
  color: #ff002e;
}

.main-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  min-height: 100vh;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 14:32:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

