.ci-rect {
  height: 8px;
}

.ci-value {
  margin: 0 10px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}
